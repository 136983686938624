import { useContext, useState} from "react";
import Input from "../../../Components/Input/Input";
import Button from "../../../Components/Button/Button";
import { ExportExcel } from "../../../Components/ExportExcel";
import { AuthContext } from "../../../Components/Context";
import { auth } from "../../../Firebase/Config";
import { useNavigate } from "react-router";
import { getAllDoc } from "../../../MiddleWare/Firebase-functions";
import { vendorsCollections } from "../../../Firebase/Collections";
import { DateDiff, getHours, setTime } from "../../../Helper/HelperFun";
import { FetchMagentoVendors } from "../../../MiddleWare/MagneoVendors";
import {PermissionsGate} from "../../../Route/PermissionsAccess";
import {UserPermissions} from "../../../Services/UserPermissions";
import {companyInfo} from "../../../Config/CompanyRole";

export const NavBar = ({  orders }) => {
  const navigate = useNavigate();
  const { setCurrentUser, setRefetch,setSubOrderId } = useContext(AuthContext);

  const ExportOrders = async () => {
    let data = orders.map((e) => ({
      subOrder: e.vendor_order_with_classification,
      itemName: e.name,
      note: e.notes
        .map((d) => d.createAt + "  " + d.createBy + ":" + d.note)
        .toString(),
      price: Number(e.price),
      sku: e.sku,
      in_warehouse: e.in_warehouse,
      vendorName: e.vendor_name,
      status: e.status,
      isSingle:e.vendor_order_with_classification.includes("S") ?"Single":"Multiple",
      confirmAt: e.confirmation_date,
      created_at: e.created_at,
      isLate:
        DateDiff(
          new Date(),
          getHours(e.confirmation_date) < companyInfo?.start_hour
            ? setTime(e.confirmation_date, companyInfo?.shift_hour)
            : e.confirmation_date
        ) >
        (e.in_warehouse == 1
          ? companyInfo?.in_warehouse_time
          : companyInfo?.out_warehouse_time),
      item_img: e.image_url,
      hourLate: DateDiff(
        new Date(),
        getHours(e.confirmation_date) < companyInfo?.start_hour
          ? setTime(e.confirmation_date, companyInfo?.shift_hour)
          : e.confirmation_date
      ),
      pick_status:e.isPick>=1 ?1:0,
      paymentMethod:e.payment_method

    }));
    ExportExcel(data);
  };

  const handelLogout = () => {
    navigate("/Login");
    setCurrentUser(null);
    auth.signOut();
  };

  return (
    <>
      <div className="container">
        <div className="navbar-wrapper">
          <div className="logo-search-wrapper">
            <div className="logo" style={{ marginRight: "10px" }}>
              <img width="80px" alt="logo" src="/Images/tamata_logo.svg" />
            </div>
<div>

            <Input
              id="search"
              style="input search"
              placeholder="Searching.... "
              type="text"
        
              onchange={(e) => {
                if(e==="") setRefetch(e=>!e);
                setSubOrderId(e)
              }}
              />
              <span style={{
                margin:"0 5px"
              }}>
              <Button
                color="c70b44d"
                style="button c70b44d"
                id="log-out"
                onclick={() => setRefetch(e=>!e)}
                title="Search"
                />
            </span>
                </div>
            <div className="footer" style={{marginLeft:"20px",cursor:"pointer"}}>
              <PermissionsGate permission={UserPermissions.pickUp}>
              <div className="title" onClick={()=>navigate("Manage_opertaion")}>
              Vendor
            </div>
              <div className="title" style={{marginLeft:"10px"}} onClick={()=>navigate("Manage_opertaion/cars")}>
              Manage Pick Up
            </div>
        
              </PermissionsGate>
              <div className="title" style={{marginLeft:"10px"}} onClick={()=>navigate("Manage_opertaion/cancel")}>
              Cancel
            </div>
          </div>
          </div>

          

          <div>

            <span>
              <Button
                color="c70b44d"
                style="button c70b44d"
                id="log-out"
                onclick={() => ExportOrders()}
                title="Export"
              />
            </span>

            <Button
              color="cffffff"
              style="button"
              id="log-out"
              title="Log Out"
              onclick={() => handelLogout()}
            ></Button>
          </div>
        </div>
       
      </div>
    </>
  );
};